import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CompanySearch.css';

function CompanySearch() {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('https://api.straightlineuae.com/api/serviceproviders')
            .then((response) => response.json())
            .then((data) => {
                setAllCompanies(data);
            })
            .catch((error) => console.error('Error fetching companies:', error));
    }, []);

    useEffect(() => {
        if (searchTerm.trim()) {
            setFilteredCompanies(
                allCompanies.filter((company) =>
                    company.companyName.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        } else {
            setFilteredCompanies([]);
        }
    }, [searchTerm, allCompanies]);

    const handleSelectCompany = (companyId) => {
        navigate(`/company-details/${companyId}`);
    };

    return (
        <div className="company-search-container">
            <input
                type="text"
                className="company-search-input"
                placeholder="Search Companies here"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            {filteredCompanies.length > 0 && (
                <ul className="company-search-results">
                    {filteredCompanies.map((company) => (
                        <li
                            key={company._id}
                            onClick={() => handleSelectCompany(company._id)}
                        >
                            {company.companyName}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default CompanySearch;
