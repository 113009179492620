// ResetPassword.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './ResetPassword.css'; // Import the CSS file for styling

function ResetPassword() {
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isOtpVerified, setIsOtpVerified] = useState(false); // Track OTP verification status
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.straightlineuae.com/api/service-provider/verify-otp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, otp }),
      });
      const data = await response.json();
      if (response.ok) {
        setIsOtpVerified(true);
        setMessage('OTP verified successfully. Please enter your new password.');
      } else {
        setMessage(data.error || 'Invalid OTP');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error verifying OTP');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const resetResponse = await fetch('https://api.straightlineuae.com/api/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, newPassword }),
      });
      const resetData = await resetResponse.json();
      if (resetResponse.ok) {
        setMessage('Password reset successfully. Redirecting to login...');
        setTimeout(() => navigate('/serviceprovider/login'), 3000); // Redirect to login after a delay
      } else {
        setMessage(resetData.error || 'Error resetting password');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error resetting password');
    }
  };

  return (
    <div className="reset-password-container">
      <h2 className="reset-password-title">Reset Password</h2>
      <form onSubmit={isOtpVerified ? handleResetPassword : handleVerifyOtp} className="reset-password-form">
        {!isOtpVerified && (
          <>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                readOnly
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>OTP</label>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                className="form-control"
                placeholder="Enter OTP"
              />
            </div>
          </>
        )}
        {isOtpVerified && (
          <div className="form-group">
            <label>New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="form-control"
              placeholder="Enter New Password"
            />
          </div>
        )}
        <button type="submit" className="btn btn-primary reset-password-button">
          {isOtpVerified ? 'Reset Password' : 'Verify OTP'}
        </button>
      </form>
      {message && <div className={`message ${message.includes('successfully') ? 'success' : 'error'}`}>{message}</div>}
    </div>
  );
}

export default ResetPassword;
