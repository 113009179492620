// ChangePassword.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ChangePassword.css';

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handlePasswordChange = () => {
    if (newPassword !== confirmPassword) {
      alert('New password and confirmation do not match.');
      return;
    }

    const serviceProvider = JSON.parse(localStorage.getItem('serviceProvider'));
    if (!serviceProvider) {
      alert('You need to be logged in to change your password.');
      navigate('/serviceprovider/login');
      return;
    }

    fetch(`https://api.straightlineuae.com/api/serviceprovider/change-password/${serviceProvider._id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ currentPassword, newPassword }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert('Password changed successfully');
          navigate('/serviceprovider/profile');
        } else {
          alert('Error changing password: ' + data.message);
        }
      })
      .catch(error => console.error('Error:', error));
  };

  return (
    <div className="change-password-container">
      <h2>Change Password</h2>
      <input
        type="password"
        placeholder="Current Password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />
      <input
        type="password"
        placeholder="New Password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <input
        type="password"
        placeholder="Confirm New Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <button className="btn btn-primary" onClick={handlePasswordChange}>Change Password</button>
    </div>
  );
}

export default ChangePassword;
