import React from 'react';
import './AboutUsPage.css';  // Custom CSS for styling


const AboutUsPage = () => {
  return (
    <div className="ab-about-page">
      <div className="ab-about-container">

        {/* Header Section */}
        <section className="ab-about-header">
          <h1>Welcome to Straight Line</h1>
          <h2>Connecting You with Trusted Service Providers Across the UAE</h2>
          <p>At Straight Line, we are dedicated to simplifying your life by connecting you with reliable and trusted service providers across the UAE. Whether you need a home service expert, a business solution, or specialized trades, we make sure you can find the professionals you need quickly and easily, saving you time and effort.</p>
        </section>

        {/* Mission Section */}
        <section className="ab-mission">
          <h3>Our Mission</h3>
          <p>Our mission is simple: to create a seamless platform that helps individuals and businesses find the services they need without the hassle. Whether you need a quick fix for your home or support for your business, Straight Line provides access to top-rated providers who can get the job done right.</p>
        </section>

        {/* Our Story Section */}
        <section className="ab-our-story">
          <h3>Our Story</h3>
          <div className="ab-founder-section">
            
            <p>
              Hi, I'm <strong>Bharath Kumar</strong>, the founder of <strong>Straight Line.</strong> The idea for this platform came from my own experience of how difficult and time-consuming it can be to find a reliable service provider. I remember when my fridge broke down in my apartment, and I spent hours on social media platforms trying to find a good technician. I encountered fake ads, incorrect numbers, and a general lack of trustworthy options. That’s when I realized that people need a reliable platform where they can easily find the right service providers.
            </p>
            <p>And that’s how Straight Line was born—a platform designed to take the stress out of finding professionals you can trust.</p>
          </div>
        </section>

        {/* Services Overview */}
        <section className="ab-services-overview">
      <h3>What We Do</h3>
      <p>We connect individuals and businesses with trusted service providers across a variety of sectors. Whether you're a homeowner or a business, we have the right provider for your needs.</p>
      <ul>
        <li><strong>Home Services:</strong> Plumbing, Electrical, Carpentry, Painting, and more.</li>
        <li><strong>Vehicle Services:</strong> Car Repair, Car Wash, and Maintenance.</li>
        <li><strong>Business Support:</strong> IT Support, HVAC, Pest Control, etc.</li>
        <li><strong>Supplies & Maintenance:</strong> Swimming Pool Maintenance, Window Cleaning, and more.</li>
        <li><strong>Specialized Trades:</strong> Carpentry, Roofing, and Pest Control.</li>
        <li><strong>Commercial Services:</strong> Transportation, Landscaping.</li>
        <li><strong>Advertising & Quotations:</strong> Connect with advertising agencies or get quotes from suppliers for your large projects.</li>
      </ul>
    </section>

        {/* Why Choose Us Section */}
        <section className="ab-why-choose-us">
      <h3>Why Choose Us?</h3>
      <ul>
        <li><strong>Convenience:</strong> With <strong>Straight Line</strong>, you can post your service needs in just a few clicks.</li>
        <li><strong>Trusted Providers:</strong> All providers are verified and reviewed for quality assurance.</li>
        <li><strong>Wide Network:</strong> We offer access to professionals across a variety of industries in the UAE.</li>
        <li><strong>Time-Saving:</strong> Receive multiple quotes without having to search across different platforms.</li>
      </ul>
    </section>

        {/* Call to Action Section */}
        <section className="ab-call-to-action">
          <h3>Ready to Find the Right Service Provider?</h3>
          <button className="ab-cta-button" onClick={() => window.location.href='/advertise-service-info'}>Post Your Requirement Now</button>
        </section>

        {/* Contact Section */}
        <footer className="ab-footer">
          <p>Contact us at: <a href="mailto:straightlineuaeinfo@gmail.com">straightlineuaeinfo@gmail.com</a></p>
        </footer>

      </div>
    </div>
  );
};

export default AboutUsPage;
