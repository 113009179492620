import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ServiceProviderRegistrationForm from './ServiceProviderRegistrationForm';
import CompanySearch from './CompanySearch';
import './MainPage.css';
import './CompanySearch';
import homeImage from '../assets/images/Stline.jpg';
import futureicon from '../assets/icons/FutureIsStraight.jpg';
import { Link } from 'react-router-dom';

function MainPage() {
  const [emirates, setEmirates] = useState([]);
  const [selectedEmirate, setSelectedEmirate] = useState('');
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState('');
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState('');
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);

  const navigate = useNavigate();

  // Fetch Emirates and Services data from the backend
  useEffect(() => {
    fetch('https://api.straightlineuae.com/api/emirates')
      .then((response) => response.json())
      .then((data) => setEmirates(data.sort((a, b) => a.emirate.localeCompare(b.emirate)))) // Sort emirates alphabetically
      .catch((error) => console.error('Error fetching emirates:', error));

    fetch('https://api.straightlineuae.com/api/services')
      .then((response) => response.json())
      .then((data) => setServices(data.sort((a, b) => a.name.localeCompare(b.name)))) // Sort services alphabetically
      .catch((error) => console.error('Error fetching services:', error));
  }, []);

  // Update areas dropdown based on selected emirate
  useEffect(() => {
    if (selectedEmirate) {
      const selectedEmirateData = emirates.find((emirate) => emirate.emirate === selectedEmirate);
      if (selectedEmirateData) {
        setAreas(selectedEmirateData.areas.sort((a, b) => a.localeCompare(b))); // Sort areas alphabetically
      } else {
        setAreas([]);
      }
    } else {
      setAreas([]);
    }
  }, [selectedEmirate, emirates]);

  const handleEmirateChange = (e) => {
    setSelectedEmirate(e.target.value);
    setSelectedArea('');
  };

  const handleAreaChange = (e) => {
    setSelectedArea(e.target.value);
  };

  const handleServiceChange = (e) => {
    setSelectedService(e.target.value);
  };

  const handleFindServiceProvider = () => {
    if (!selectedEmirate) {
      alert("Please select an Emirate.");
      return;
    }

    // Build the query parameters based on the selected options
    const queryParams = new URLSearchParams();
    queryParams.append('emirate', selectedEmirate);
    
    if (selectedArea) {
        queryParams.append('area', selectedArea);
    }

    if (selectedService) {
        queryParams.append('service', selectedService);
    }

    // Navigate to the service providers page with the selected query parameters
    navigate(`/service-providers?${queryParams.toString()}`);
  };

  return (
    <div className="main-container">
      <div className="caution-note">
          ⚠️ This webpage is currently under development. Features and content may change
        </div>       
      {showRegistrationForm ? (
        <ServiceProviderRegistrationForm />
      ) : (
        <div className="service-finder-container">
          <CompanySearch />

          <h1>FIND</h1>
          <h2>YOUR SERVICE PROVIDER</h2>
          <h2>IN UAE</h2>
          <div className="dropdown-container">
            <select className="form-select" value={selectedEmirate} onChange={handleEmirateChange}>
              <option value="">Select Emirate</option>
              {emirates.map((emirate) => (
                <option key={emirate._id} value={emirate.emirate}>
                  {emirate.emirate}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown-container">
            <select className="form-select" value={selectedArea} onChange={handleAreaChange} disabled={!selectedEmirate}>
              <option value="">Select Area</option>
              {areas && areas.map((area, index) => (
                <option key={index} value={area}>
                  {area}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown-container">
            <select className="form-select" value={selectedService} onChange={handleServiceChange}>
              <option value="">Select Service</option>
              {services.map((service) => (
                <option key={service._id} value={service.name}>
                  {service.name}
                </option>
              ))}
            </select>
            <button className="find-provider-button" onClick={handleFindServiceProvider}>
              FIND
            </button>
          </div>          
          <button className='ad-yourself' onClick={() => navigate('/advertise-service-info')}><strong>Post Your Requirements <br /> – No Fees, It's Free! </strong></button>
          <div className="register-link1">
            <Link to="/service-provider-info">Click Here for Service Provider Registration</Link>
          </div>
          <div className="image-container">
          <img src={futureicon} alt="Home" />
            <img src={homeImage} alt="Home" />
          </div>
	        <div className="business-description">
            <h3>Welcome to Straight Line </h3>
            <h4>– Your One-Stop Solution for Finding the Right Service Providers and Suppliers in the UAE</h4>
            <p>
              We’ve created a seamless platform that connects you with top-rated service providers across the UAE,
              making it easier than ever to find the right professionals for your home and business needs. Whether
              you need an electrician, plumber, carpenter, or any other service, our app ensures that you can quickly
              locate and connect with trusted experts in your area.
            </p>
            <p>For service providers, Straight Line offers an unparalleled
              opportunity to expand your reach, grow your business, and connect with new clients who need your skills. We’re
              committed to building a reliable, efficient, and user-friendly marketplace where quality meets convenience.
            </p>
            <Link to="/our-services">Click Here to Learn More</Link> 
          </div>
        </div>
      )}
    </div>
  );
}

export default MainPage;
