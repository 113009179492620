import React, { useState, useEffect } from 'react';
import './ApplyVerification.css';
import { useNavigate } from 'react-router-dom';

function ApplyVerification() {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [providerId, setProviderId] = useState(null);
    const [provider, setProvider] = useState(null);  // Add provider state

    const navigate = useNavigate();

    useEffect(() => {
        const storedProvider = JSON.parse(localStorage.getItem('serviceProvider'));
        if (storedProvider && storedProvider._id) {
            setProviderId(storedProvider._id);  // Store the provider's ID
            setProvider(storedProvider);  // Store the provider object
        } else {
            setErrorMessage('No service provider ID found.');
        }
    }, []);

    const handleApplyVerification = async () => {
        if (!providerId) {
            setErrorMessage('No service provider ID found.');
            return;
        }

        try {
            const response = await fetch(`https://api.straightlineuae.com/api/verification-apply/${providerId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                setSuccessMessage('Verification application submitted successfully.');
                setErrorMessage('');
            } else {
                setErrorMessage('Error submitting verification application.');
            }
        } catch (error) {
            setErrorMessage('Error submitting verification application.');
        }
    };

    return (
        <div className="apply-verification-container">
            <h2>Service Provider Verification</h2>
            <p>
                Verification helps build trust with your customers and allows you to access premium features.
            </p>
            <button disabled={provider && provider.applicationStatus === "pending"} 
            onClick={handleApplyVerification} 
            className="apply-verification-btn">
               {provider && provider.applicationStatus === "pending" ? "Application Pending" : "Apply for Verification"}
            </button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
        </div>
    );
}

export default ApplyVerification;
