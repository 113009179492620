import React from 'react';
import './OurServicesPage.css';  // Custom CSS for styling
import connectImage from '../assets/images/St_connect.jpg';

const OurServicesPage = () => {
  return (
        <div className='service-page'>
            <div className='our-services-container'>
                    <header className="header-section">
                        <h1>Connecting You with Trusted Service Providers & Suppliers</h1>
                        <h2>Straight Line – Your One-Stop Solution for Finding the Right Service Providers and Suppliers in the UAE.</h2>
                        <div className="image-connect">
                            <img src={connectImage} alt="Connect" />
                        </div>
                        <button className="cta-button" onClick={() => window.location.href='/advertise-service-info'}>
                            Get Started Now
                        </button>
                    </header>

            

                    <section className="what-we-offer">
                        <h3>Our Services at a Glance</h3>

                        <p>
                            At Straight Line, we bridge the gap between service providers and those in need of their services. Whether you are a business looking to expand your reach or an individual searching for a trusted professional, our platform has the solution. Here's how we can help:
                        </p>

                        <h4>Service Categories:</h4>

                        <ul>
                            <li><strong>Home Services:</strong> Carpentry, Electrical, Plumbing, Housekeeping, Painting, etc.</li>
                            <li><strong>Vehicle Services:</strong> Car Repair, Car Wash, and Maintenance.</li>
                            <li><strong>Business Support:</strong> IT Support, HVAC, Pest Control, and more.</li>
                            <li><strong>Supplies & Maintenance:</strong> Swimming Pool Maintenance, Window Cleaning, Roofing, etc.</li>
                            <li><strong>Specialized Trades:</strong> Carpentry, Roofing, Pest Control.</li>
                            <li><strong>Commercial Services:</strong> Transportation, Landscaping.</li>
                            <li><strong>Advertising & Quotations:</strong> Connect with advertising agencies, get quotes from multiple suppliers for large-scale projects or business needs.</li>
                        </ul>
                    </section>


                    <section className="how-it-works">
                        <h3>How Straight Line Simplifies Your Search</h3>

                        <div className="step">
                            <h4>Step 1: Add Your Requirement</h4>
                            <p>
                            Post your need or requirement in a few simple steps. Whether you’re looking for a single service or multiple providers, just fill out the details, and we’ll handle the rest.
                            </p>
                        </div>

                        <div className="step">
                            <h4>Step 2: Service Providers Reach Out</h4>
                            <p>
                            Our verified service providers and suppliers will contact you with offers, making it easy to compare and select the best option.
                            </p>
                        </div>

                        <div className="step">
                            <h4>Step 3: Choose the Best Fit</h4>
                            <p>
                            Once you receive multiple offers or quotations, choose the one that fits your needs and budget. We make sure you get competitive pricing and quality service.
                            </p>
                        </div>
                    </section>


                    <section className="why-choose-us">
                        <h3>Why Choose Us?</h3>

                        <ul>
                            <li>
                            <strong>Convenience:</strong> Eliminate the need for calling multiple companies – we bring the best options to you.
                            </li>
                            <li>
                            <strong>Wide Network:</strong> Access to a vast network of trusted professionals and suppliers across the UAE.
                            </li>
                            <li>
                            <strong>Time & Cost Savings:</strong> Get multiple quotes and offers, helping you make informed decisions while saving time and money.
                            </li>
                            <li>
                            <strong>Secure Platform:</strong> We ensure that all service providers are verified for your peace of mind.
                            </li>
                        </ul>
                    </section>

                    <section className="industries-we-serve">
                        <h3>Industries We Cover</h3>

                        <p>
                            No matter what industry you’re in, we have the right providers for you. From construction to maintenance, IT to hospitality, our platform is designed to serve a variety of sectors.
                        </p>

                        <ul>
                            <li><strong>Construction</strong></li>
                            <li><strong>Real Estate</strong></li>
                            <li><strong>Hospitality</strong></li>
                            <li><strong>IT & Technology</strong></li>
                            <li><strong>Automotive</strong></li>
                            <li><strong>Retail</strong></li>
                            <li><strong>Healthcare</strong></li>
                        </ul>
                    </section>

                    <section className="register-service-provider">
                        <h3>Grow Your Business with Straight Line</h3>
                        <p>
                            Are you a service provider or supplier looking to expand your reach? Register with Straight Line and get direct access to customers actively searching for your services.
                        </p>

                        <button className="cta-button" onClick={() => window.location.href='/service-provider-info'}>
                            Register as a Service Provider
                        </button>
                    </section>

                    <section className="cta">
                        <h3>Ready to Find the Perfect Service Provider?</h3>

                        <button className="cta-button" onClick={() => window.location.href='/advertise-service-info'}>
                            Post Your Requirement Now
                        </button>                        
                    </section>
                                
            </div>
        </div>
  );
};

export default OurServicesPage;
