import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [step, setStep] = useState(1); // Step 1: Send OTP, Step 2: Verify OTP and Reset Password
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when sending OTP
    try {
      const response = await fetch('https://api.straightlineuae.com/api/service-provider/send-otp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage('OTP has been sent to your email. Please check your inbox.');
        setStep(2); // Move to step 2
      } else {
        setMessage(data.error || 'Error sending OTP');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error sending OTP');
    } finally {
      setLoading(false); // Set loading to false after response
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when resetting password
    try {
      const response = await fetch('https://api.straightlineuae.com/api/service-provider/verify-otp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, otp }),
      });
      const data = await response.json();
      if (response.ok) {
        // Proceed to reset password after OTP verification
        const resetResponse = await fetch('https://api.straightlineuae.com/reset-password', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, newPassword }),
        });
        const resetData = await resetResponse.json();
        if (resetResponse.ok) {
          setMessage('Password reset successfully. You can now log in with your new password.');
          setTimeout(() => navigate('/serviceprovider/login'), 3000); // Redirect to login after a delay
        } else {
          setMessage(resetData.error || 'Error resetting password');
        }
      } else {
        setMessage(data.error || 'Invalid OTP');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error resetting password');
    } finally {
      setLoading(false); // Set loading to false after response
    }
  };

  return (
    <div className="forgot-password-container">
      <h2>Forgot Password</h2>
      {step === 1 && (
        <form onSubmit={handleSendOtp}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? 'Sending OTP...' : 'Send OTP'}
          </button>
        </form>
      )}
      {step === 2 && (
        <form onSubmit={handleResetPassword}>
          <div className="form-group">
            <label>OTP</label>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
              className="form-control"
              placeholder="Enter OTP"
            />
          </div>
          <div className="form-group">
            <label>New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="form-control"
              placeholder="Enter New Password"
            />
          </div>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? 'Resetting Password...' : 'Reset Password'}
          </button>
        </form>
      )}
      {message && <div className={`message ${message.includes('successfully') ? 'success' : 'error'}`}>{message}</div>}
    </div>
  );
}

export default ForgotPassword;
