import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import MainPage from './UserComponents/MainPage';
import ServiceProviderList from './UserComponents/ServiceProviderList';
import ServiceProviderLogin from './UserComponents/ServiceProviderLogin';
import ServiceProviderProfile from './UserComponents/ServiceProviderProfile';
import ChangePassword from './UserComponents/ChangePassword';
import './App.css';
import './UserComponents/MainPage.css';
import './UserComponents/ServiceProviderRegistrationForm.css';
import CompanyDetails from './UserComponents/CompanyDetails';
import ServiceProviderInfo from './UserComponents/ServiceProviderInfo';
import ServiceProviderRegistrationForm from './UserComponents/ServiceProviderRegistrationForm';
import TrackAdvertisement from './UserComponents/TrackAdvertisement'; // Import the new component
import ForgotPassword from './UserComponents/ForgotPassword'; 
import ResetPassword from './UserComponents/ResetPassword';
import AdvertiseServicePage from './UserComponents/AdvertiseServicePage';
import AdvertiseServiceForm from './UserComponents/AdvertiseServiceForm';
import VerificationDetails from './UserComponents/VerificationDetails';
import ApplyVerification from './UserComponents/ApplyVerification';
import AdvertisementList from './UserComponents/AdvertisementsList';
import OurServicesPage from './UserComponents/OurServicesPage';
import ScrollToTop from './UserComponents/ScrollToTop';
import AboutUsPage from './UserComponents/AboutUsPage'; 

function App() {
  // Function to close the menu
  const closeMenu = () => {
    document.getElementById('menu-toggle').checked = false;
  };

  return (
    <Router>
      <ScrollToTop />      
      <div className="app-container">      
        <header className="header-bar">
          <div className="logo"> 
            <Link to="/" onClick={closeMenu}>
              <h1>STRAIGHT LINE</h1>
            </Link>
          </div>            
          <nav className="menu-icon">
            <input type="checkbox" id="menu-toggle" />
            <label htmlFor="menu-toggle" className="menu-button-container">
              <div className="menu-button"></div>
            </label>
            <ul className="menu">
              <li><Link to="/our-services" onClick={closeMenu}>Our Services</Link></li>
              <li><Link to="/serviceprovider/login" onClick={closeMenu}>Service Provider Login</Link></li>
              <li><Link to="/track-advertisement" onClick={closeMenu}>Track Your Advertisement</Link></li>
              <li><Link to="/about-us" onClick={closeMenu}>About Us</Link></li>
              <li><Link to="#" onClick={closeMenu}>Contact Us</Link></li>
            </ul>
          </nav>
        </header>

        <main className='main-container1'>
          <Routes>
            <Route path="/" element={<MainPage />} /> 
            <Route path="/our-services" element={<OurServicesPage />} />           
            <Route path="/service-providers" element={<ServiceProviderList />} />
            <Route path="/serviceprovider/login" element={<ServiceProviderLogin />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/serviceprovider/profile" element={<ServiceProviderProfile />} />
            <Route path="/serviceprovider/change-password" element={<ChangePassword />} />
            <Route path="/company-details/:companyId" element={<CompanyDetails />} />            
            <Route path="/service-provider-info" element={<ServiceProviderInfo />} />
            <Route path="/Register" element={<ServiceProviderRegistrationForm />} />
            <Route path="/track-advertisement" element={<TrackAdvertisement />} /> {/* New route */}
            <Route path="/serviceprovider/forgot-password" element={<ForgotPassword />} />            
            <Route path="/serviceprovider/reset-password" element={<ResetPassword />} /> 
            <Route path="/advertise-service-info" element={<AdvertiseServicePage />} />
            <Route path="/advertise-service-form" element={<AdvertiseServiceForm />} />
            <Route path='/Get-Verification' element = {<VerificationDetails />} />
            <Route path='/apply-verification' element = {<ApplyVerification />} />
            <Route path='/advertisements' element ={<AdvertisementList />} />

          </Routes>
        </main>

        <footer className="footer-bar">
          <div className="footer-content">
            <div className="footer-section">
              <h4>Contact Us</h4>              
              <p>Email: straightlineuaeinfo@gmail.com</p>              
            </div>
            <div className="footer-section">
            <div className="caution-note">
              ⚠️ This webpage is currently under development. Features and content may change
            </div>       
              <h4>Follow Us</h4>
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a> | 
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a> | 
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
            </div>
            <div className="footer-section">
              <p>&copy; 2024 STRAIGHT LINE. All Rights Reserved.</p>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;

///"Trigger Amplify"