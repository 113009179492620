import React, { useEffect, useState } from 'react';
import './AdvertisementsList.css';

const AdvertisementsList = () => {
  const [ads, setAds] = useState([]);
  const [selectedAd, setSelectedAd] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = window.location;
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const area = params.get('area');
    const service = params.get('service');

    fetch(`https://api.straightlineuae.com/api/advertisements?area=${area}&service=${service}`)
      .then((res) => res.json())
      .then((data) => setAds(data))
      .catch((err) => console.error('Error fetching advertisements:', err));
  }, [location.search]);

  const handleAdClick = (ad) => {
    setSelectedAd(ad);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const calculateTimePassed = (date) => {
    const now = new Date();
    const postedDate = new Date(date);
    const difference = now - postedDate;

    const daysPassed = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hoursPassed = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    if (daysPassed > 0) {
      return `${daysPassed} days ago`;
    } else if (hoursPassed > 0) {
      return `${hoursPassed} hours ago`;
    } else {
      return 'Just now';
    }
  };

  return (
    <div className="advertisements-container">
      <h1>Customer Advertisements in Your Area</h1>
      <ul className="advertisement-list">
        {ads.map((ad, index) => (
          <li key={ad._id} onClick={() => handleAdClick(ad)}>
            <div className="serial-number">{index + 1}</div>
            <div>
              <strong>Name:</strong> {ad.name} <br />
              <strong>Mobile:</strong> {ad.mobile} <br />
              <strong>Service Needed:</strong> {ad.service} <br />
              <strong>Description:</strong> {ad.description.split('\n').map((line, i) => (
                <span key={i}>{line}<br /></span>
              ))} 
              <strong>Posted:</strong> {calculateTimePassed(ad.date)}
            </div>
          </li>
        ))}
      </ul>

      {isModalOpen && selectedAd && (
        <div className="modal1" onClick={closeModal}>
          <div className="modal-content1">
            <h2>Details for {selectedAd.name}</h2>
            <p><strong>Mobile:</strong> {selectedAd.mobile}</p>
            <p><strong>Service Needed:</strong> {selectedAd.service}</p>
            <p><strong>Description:</strong></p>
            <p>{selectedAd.description.split('\n').map((line, i) => (
              <span key={i}>{line}<br /></span>
            ))}</p>
            <p><strong>Posted:</strong> {new Date(selectedAd.date).toLocaleDateString()}</p>
            <p><strong>Time Passed:</strong> {calculateTimePassed(selectedAd.date)}</p>
            <button className="close-button" onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvertisementsList;
