import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './CompanyDetails.css';

function CompanyDetails() {
    const { companyId } = useParams();
    const [provider, setProvider] = useState(null);
    const [images, setImages] = useState({ profilePic: null, poster: '' });    
    const [isModalOpen, setIsModalOpen] = useState(false);


    useEffect(() => {
        fetch(`https://api.straightlineuae.com/api/serviceproviders/${companyId}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setProvider(data.serviceProvider);  // Set the service provider details
                setImages(data.images);             // Set the images (profilePic and poster)
            })
            .catch(error => console.error('Error fetching company details:', error));
    }, [companyId]);

    // Toggle modal open/close
    const handlePosterClick = () => {        
        setIsModalOpen(true);  // This should open the modal
    };
    

    const closeModal = () => {
        setIsModalOpen(false);
    };

    if (!provider) {
        return <div className='loading'> Loading... </div>;
    }

    return (
        <div className="profile-container">
            {provider && (
                <>
                     
                    <div className="header">
                        <div className="profile-pic">
                            {images && images.profilePic ? (
                                <img src={`${images.profilePic}?${new Date().getTime()}`} alt="Logo" />
                            ) : (
                                <div className="default-logo">{provider.companyName.charAt(0)}</div>
                            )}
                        </div>

                        <div className="company-info">
                            <div className='company-name-status'>
                                <h1>{provider.companyName}</h1>
                                <h2 className={provider.verificationStatus ? "verified-status" : "not-verified-status"}>
                                    {provider.verificationStatus ? "Verified" : "Not verified"}
                                </h2>
                            </div>
                            <div className="description-container">
                                <p>{provider.description}</p>
                            </div>
                        </div>
                    </div>

                    <div className="line"></div>

                    <div className="services-areas">
                        <div className="section-block services">
                            <h3>Services</h3>
                            <p>{provider.services.join(', ')}</p>
                        </div>
                        <div className="section-block areas">
                            <h3>Areas</h3>
                            <p>{provider.areas.join(', ')}</p>
                        </div>
                    </div>

                    <div className="contact-details">
                        <h3>Contact Details</h3>
                        <p><strong>Phone:</strong> {provider.mobile}</p>
                        <p><strong>Email:</strong> {provider.email}</p>
                        <p><strong>Contact Person:</strong> {provider.name}</p>
                        <p><strong>Address:</strong> {provider.address}</p>
                    </div>

                    <div className="post-section1">
                        <div className="post-images1" onClick={handlePosterClick}>
                            {images && images.poster ? (
                                <img src={`${images.poster}?${new Date().getTime()}`} alt="Poster" />
                            ) : (
                                <div className="default-poster1">No Poster Available</div>
                            )}
                        </div>
                    </div>

                    {/* Modal Section */}
                    {isModalOpen && (
                        <div className="modal1" onClick={closeModal}>                            
                            <span className="close1" onClick={closeModal}>&times;</span>
                            <img className="modal-content1" src={`${images.poster}?${new Date().getTime()}`} alt="Poster Full View" />
                        </div>
                    )}

                   
                </>
            )}
        </div>
    );
}

export default CompanyDetails;
