import React from 'react';
import { useNavigate } from 'react-router-dom';
import './VerificationDetails.css';


const VerificationDetails = () => {
  const navigate = useNavigate();

  const handleApplyVerification = () => {
    // Redirect to apply verification
    navigate('/apply-verification');
  };

  return (
    <div className="verification-details">
      <h1>Service Provider Verification</h1>
      <p>
        Verification helps your business to gain trust from customers, and allows you to access premium features such as viewing advertisements for customers in your service area. Verified service providers also appear higher in search results.
      </p>
      <h3>Benefits of Verification:</h3>
      <ul>
        <li>Increased customer trust</li>
        <li>Higher ranking in search results</li>
        <li>Access to customer advertisements in your location</li>
      </ul>
      <p><strong>Verification Fee:</strong> AED 100</p>
      <p><strong>Terms and Conditions:</strong> We will contact you for the verification process, which includes an interview and submission of business documents.</p>
      <button onClick={handleApplyVerification}>Apply for Verification</button>
    </div>
  );
};

export default VerificationDetails;
