import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './ServiceProviderLogin.css';

function ServiceProviderLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    // Here, you would typically send the email and password to your backend for authentication
    fetch('https://api.straightlineuae.com/api/serviceprovider/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // Store provider information in localStorage
          localStorage.setItem('serviceProvider', JSON.stringify(data.provider));
      
          // Store image information (profilePic and poster) in localStorage
          localStorage.setItem('serviceProviderImages', JSON.stringify(data.images));
      
          // Redirect to profile or update state with provider and images details
          navigate('/serviceprovider/profile');
      } else {
          alert('Login failed. Please check your credentials and try again.');
        }
      })
      .catch(error => console.error('Error during login:', error));
  };

  return (
    <div className="login-container">
      <h2>Service Provider Login</h2>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Login</button>
        <div className="forgot-password">
          <button 
            type="button" 
            className="btn btn-link" 
            onClick={() => navigate('/serviceprovider/forgot-password')}>
            Forgot Password?
          </button>
          <div className="register-link">
              <Link to="/service-provider-info">Click Here for Service Provider Registration</Link>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ServiceProviderLogin;
