import React, { useState, useEffect } from 'react';
import './AdvertiseServiceForm.css';
import { useNavigate } from 'react-router-dom';

function AdvertiseServiceForm() {
  const [emirates, setEmirates] = useState([]);
  const [selectedEmirate, setSelectedEmirate] = useState('');
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState('');
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    description: ''
  });
  const [isLoading, setIsLoading] = useState(false); // Added state for loading
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://api.straightlineuae.com/api/emirates')
      .then(response => response.json())
      .then((data) => setEmirates(data.sort((a, b) => a.emirate.localeCompare(b.emirate))))
      .catch(error => console.error('Error fetching emirates:', error));

    fetch('https://api.straightlineuae.com/api/services')
      .then(response => response.json())
      .then((data) => setServices(data.sort((a, b) => a.name.localeCompare(b.name))))
      .catch(error => console.error('Error fetching services:', error));
  }, []);

  useEffect(() => {
    if (selectedEmirate) {
      const selectedEmirateData = emirates.find(emirate => emirate.emirate === selectedEmirate);
      if (selectedEmirateData) {
        setAreas(selectedEmirateData.areas.sort((a, b) => a.localeCompare(b)));
      } else {
        setAreas([]);
      }
    } else {
      setAreas([]);
    }
  }, [selectedEmirate, emirates]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      alert('Invalid email address. Please enter a valid email.');
      return;
    }

    setIsLoading(true); // Set loading to true when form submission starts

    const advertisementData = {
      ...formData,
      emirate: selectedEmirate,
      area: selectedArea,
      service: selectedService,
    };

    fetch('https://api.straightlineuae.com/api/advertise-service', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(advertisementData),
    })
      .then(response => response.json())
      .then(data => {
        setIsLoading(false); // Reset loading to false after response

        if (data.error) {
          alert(data.error);
        } else {
          alert(`Service advertised successfully! Your tracking number is: ${data.trackingNumber}`);
          setFormData({
            name: '',
            mobile: '',
            email: '',
            description: ''
          });
          setSelectedEmirate('');
          setSelectedArea('');
          setSelectedService('');
          navigate('/'); // Redirect to the main page after success
        }
      })
      .catch(error => {
        setIsLoading(false); // Reset loading to false if there's an error
        console.error('Error advertising service:', error);
      });
  };

  return (
    <div className="advertise-form-container">
      <form onSubmit={handleFormSubmit}>
        {/* Form fields */}
        <div>
          <label htmlFor="emirate">Emirate:</label>
          <select
            id="emirate"
            name="emirate"
            value={selectedEmirate}
            onChange={(e) => setSelectedEmirate(e.target.value)}
          >
            <option value="">Select an Emirate</option>
            {emirates.map((emirate) => (
              <option key={emirate.emirate} value={emirate.emirate}>
                {emirate.emirate}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="area">Area:</label>
          <select
            id="area"
            name="area"
            value={selectedArea}
            onChange={(e) => setSelectedArea(e.target.value)}
          >
            <option value="">Select an Area</option>
            {areas.map((area) => (
              <option key={area} value={area}>
                {area}
              </option>
            ))}
          </select>
        </div>        
        <div>
          <label>Service</label>
          <select value={selectedService} onChange={(e) => setSelectedService(e.target.value)} required>
            <option value="">Select Service</option>
            {services.map(service => (
              <option key={service._id} value={service.name}>{service.name}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Name</label>
          <input type="text" name="name" value={formData.name} onChange={handleInputChange} required />
        </div>
        <div>
          <label>Mobile</label>
          <input type="text" name="mobile" value={formData.mobile} onChange={handleInputChange} required />
        </div>
        <div>
          <label>Email</label>
          <input type="email" name="email" value={formData.email} onChange={handleInputChange} required />
        </div>
        <div>
          <label>Description</label>
          <textarea name="description" value={formData.description} onChange={handleInputChange} required></textarea>
        </div>
        {/* Submit button with loading indicator */}
        <button className='Advsubmit' type="submit" disabled={isLoading}>
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  );
}

export default AdvertiseServiceForm;
