import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaTools } from 'react-icons/fa'; // Importing a service icon
import './ServiceProviderList.css';

function ServiceProviderList() {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const selectedEmirate = queryParams.get('emirate');
  const selectedService = queryParams.get('service');
  const selectedArea = queryParams.get('area');

  const [serviceProviders, setServiceProviders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchServiceProviders = async () => {
      const queryParams = new URLSearchParams(location.search);
      queryParams.append('limit', 20);  // Show 20 results per page
      queryParams.append('page', currentPage);  // Fetch the selected page

      try {
        const response = await fetch(`https://api.straightlineuae.com/api/serviceproviders/filter?${queryParams.toString()}`);
        const data = await response.json();
        setServiceProviders(data.serviceProviders);
        setTotalPages(data.pages);
        setCurrentPage(data.page);
      } catch (error) {
        console.error('Error fetching service providers:', error);
      }
    };

    fetchServiceProviders();
  }, [location.search, currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleCompanyClick = (companyId) => {
    navigate(`/company-details/${companyId}`);
  };

  const handleAdvertiseRedirect = () => {
    navigate('/advertise-service-info', {
      state: { selectedEmirate, selectedArea, selectedService }
    });
  };

  return (
    <div className="splist-provider-list-container">
      <p>{serviceProviders.length} {selectedService || 'Service'} Providers Available in your Location</p>
      <div className="splist-provider-list">
        {serviceProviders.length > 0 ? (
          serviceProviders.map((provider, index) => (
            <div key={index} className="splist-provider-item" onClick={() => handleCompanyClick(provider._id)}>
              <h3>{provider.companyName}</h3>
              <p className={provider.verificationStatus ? "p-verified-status" : "p-not-verified-status"}>
                <strong>{provider.verificationStatus ? "- Verified" : "- Not verified"}</strong>
              </p>
              <p><FaTools className="splist-icon" /> {provider.services.join(', ')}</p> 
              <p><FaPhoneAlt className="splist-icon" /> {provider.mobile}</p>
              {/* <p><FaEnvelope className="splist-icon" /> {provider.email}</p> */}
              <p><FaMapMarkerAlt className="splist-icon" /> {provider.address}</p>              
            </div>
          ))
        ) : (
          <p>No service providers found for the selected criteria.</p>
        )}
      </div>

      {totalPages > 1 && (
        <div className="pagination-container">
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            {'<<'}
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            {'>>'}
          </button>
        </div>
      )}

      <div className="splist-advertise-link">
        <button className="btn-primary1" onClick={handleAdvertiseRedirect}>
          ADVERTISE YOURSELF FOR SERVICE HERE
        </button>
      </div>
    </div>
  );
}

export default ServiceProviderList;
