import React from 'react';
import { Link } from 'react-router-dom';
import './ServiceProviderInfo.css';

const ServiceProviderInfo = () => {
  return (
    <div className="provider-info-container">
      <h1>Join the Straight Line Platform</h1>
      <section className="intro-section">
        <p>At Straight Line, we connect top-rated service providers with customers across the UAE.</p>
      </section>

      <section className="benefits-section">
        <h2>Benefits of Joining</h2>
        <ul>
          <li>Expand your customer base.</li>
          <li>Manage your profile and services with ease.</li>
          <li>Receive real-time service requests.</li>
        </ul>
      </section>

      <section className="how-it-works-section">
        <h2>How It Works</h2>
        <p>Register, list your services, and start receiving requests from customers in your area.</p>
      </section>

      <section className="why-choose-us-section">
        <h2>Why Choose Us?</h2>
        <p>Our platform is trusted by thousands and provides secure payment options and customer support.</p>
      </section>

      <section className="get-started-section">
        <h2>Get Started</h2>
        <p>Ready to grow your business? Click below to register and start receiving new service requests today.</p>
        <Link to="/Register" className="register-button">
          Register Now
        </Link>
      </section>
    </div>
  );
};

export default ServiceProviderInfo;
