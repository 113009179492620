import React, { useState, useEffect } from 'react';
import './ServiceProviderRegistrationForm.css';
import { useNavigate } from 'react-router-dom';

const ServiceProviderRegistrationForm = () => {
  const [formData, setFormData] = useState({
    emirate: '',
    areas: [],
    services: [],
    name: '',
    companyName: '',
    address: '',
    mobile: '',
    email: '',
    description: '',
    password: '',
    otp: '',
  });

  const [emirates, setEmirates] = useState([]);
  const [availableAreas, setAvailableAreas] = useState([]);
  const [availableServices, setAvailableServices] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state for the button
  const [verified, setVerified] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmirates = async () => {
      try {
        const response = await fetch('https://api.straightlineuae.com/api/emirates');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();        
        setEmirates(data.sort((a, b) => a.emirate.localeCompare(b.emirate))); // Sort alphabetically
      } catch (error) {
        console.error('Error fetching emirates:', error);
      }
    };

    const fetchServices = async () => {
      try {
        const response = await fetch('https://api.straightlineuae.com/api/services');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setAvailableServices(data.map(service => service.name).sort()); // Sort alphabetically
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchEmirates();
    fetchServices();
  }, []);

  const handleEmirateChange = (e) => {
    const selectedEmirate = e.target.value;
    setFormData({ ...formData, emirate: selectedEmirate, areas: [] });

    const emirateData = emirates.find((emirate) => emirate.emirate === selectedEmirate);
    if (emirateData) {
      setAvailableAreas(emirateData.areas.sort()); // Sort alphabetically
    }
  };

  const handleAreaChange = (e) => {
    const selectedArea = e.target.value;
    if (formData.areas.includes(selectedArea)) return;
    if (formData.areas.length < 3) {
      setFormData({ ...formData, areas: [...formData.areas, selectedArea] });
    } else {
      alert('You can select a maximum of 3 areas.');
    }
  };

  const handleServiceChange = (e) => {
    const selectedService = e.target.value;
    if (formData.services.includes(selectedService)) return;
    if (formData.services.length < 3) {
      setFormData({ ...formData, services: [...formData.services, selectedService] });
    } else {
      alert('You can select a maximum of 3 services.');
    }
  };

  const handleRemoveArea = (areaToRemove) => {
    setFormData({ ...formData, areas: formData.areas.filter((area) => area !== areaToRemove) });
  };

  const handleRemoveService = (serviceToRemove) => {
    setFormData({ ...formData, services: formData.services.filter((service) => service !== serviceToRemove) });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const handleSendOtp = async () => {
  const { email } = formData;

  // Check if email is empty
  if (!email) {
    setMessage('Please enter your email address.');
    return;
  }

  // Check if email format is valid
  if (!validateEmail(email)) {
    setMessage('Invalid email format. Please enter a valid email.');
    return;
  }

  setIsLoading(true); // Show loading state

  try {
    const response = await fetch('https://api.straightlineuae.com/api/registration-otp/send-registration-otp', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });

    const result = await response.json();

    // Handle specific server responses for 409 status
    if (response.status === 409) {
      if (result.error === 'Email already exists. Please try to log in.') {
        setMessage('Email already exists. Please try to log in.');
      } else if (result.error === 'Email already registered. Please wait for verification.') {
        setMessage('Email already registered. Please wait for verification.');
      }
      setIsLoading(false);
      return;
    }

    // Handle failure response
    if (!response.ok) {
      throw new Error('Failed to send OTP');
    }

    // Successful OTP sent
    setOtpSent(true);
    setMessage('OTP sent successfully!');
  } catch (error) {
    console.error('Error sending OTP:', error);
    setMessage('Error sending OTP. Please try again later.');
  } finally {
    setIsLoading(false); // Reset loading state
  }
};
  

  const handleVerifyOtp = async () => {   
    setIsLoading(true); 
    try {
        const response = await fetch('https://api.straightlineuae.com/api/registration-otp/verify-registration-otp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: formData.email, otp: formData.otp }),
        });

        if (response.ok) {
          setOtpVerified(true); // OTP verified, hide OTP fields
          setVerified(true); // Disable email field after OTP verification
          setMessage('OTP verified successfully!');
              } else {
                  setMessage('Invalid OTP. Please try again.');
              }
          } catch (error) {
              setMessage('Error verifying OTP. Please try again later.');
          } finally {
            setIsLoading(false);
          }
        };  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    if (!otpVerified) {
      alert('Please verify your email with the OTP sent.');
      setIsLoading(false); // Stop loading if OTP is not verified
      return;
    }

    try {
      const response = await fetch('https://api.straightlineuae.com/api/register-service-provider', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to submit data');
      } else {
        alert(`Service advertised successfully! Check Your Email: ${formData.email}`);
        setIsSubmitted(true);
        setFormData({
          emirate: '',
          areas: [],
          services: [],
          name: '',
          companyName: '',
          address: '',
          mobile: '',
          email: '',
          description: '',
          password: '',
          otp: '',
      });

      // Redirect to a success page after successful submission
      navigate('/'); // Redirect to the main page after success

    }

    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };


  return (
    <div className="service-provider-registration">
      <h1>Service Provider Registration</h1>
      
      <form onSubmit={handleSubmit}>

        <div className="email-verification-section">
              <input
                className="v-email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                disabled={verified}
                placeholder="Email"
                required
              />        

              {!otpSent && (
                <button className="send-otp" type="button" onClick={handleSendOtp} disabled={isLoading}>
                  {isLoading ? 'Sending...' : 'Send OTP'}
                </button>
              )}

                {otpSent && !otpVerified && (
                    <>
                        <input
                          type="text"
                          name="otp"
                          value={formData.otp}
                          onChange={handleChange}
                          placeholder="Enter OTP"
                          required
                        />
                        <button className="verify-otp" type="button" onClick={handleVerifyOtp}>
                          {isLoading ? 'Verifying...' : 'Verify OTP'}
                        </button>            
                    </>
                )}

              {message && <div>{message}</div>}
              
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />

              <div className="show-password">
              Show Password 
                <input className="checkbox" type="checkbox" onChange={() => setShowPassword(!showPassword)} /> 
              </div>

          </div>

        <select name="emirate" value={formData.emirate} onChange={handleEmirateChange} required>
          <option value="">Select Emirate</option>
          {emirates.map((emirate) => (
            <option key={emirate.emirate} value={emirate.emirate}>
              {emirate.emirate}
            </option>
          ))}
        </select>

        <select name="areas" value="" onChange={handleAreaChange}>
          <option value="">Select Area</option>
          {availableAreas.map((area) => (
            <option key={area} value={area}>
              {area}
            </option>
          ))}
        </select>
        <small>(Maximum 3 Areas can be selected)</small>
        <div className="selected-items">
          <ul>
            {formData.areas.map((area) => (
              <li key={area} className="selected-item">
                {area}
                <button type="button" onClick={() => handleRemoveArea(area)}>&times;</button>
              </li>
            ))}
          </ul>
        </div>

        <select name="services" value="" onChange={handleServiceChange}>
          <option value="">Select Service</option>
          {availableServices.map((service) => (
            <option key={service} value={service}>
              {service}
            </option>
          ))}
        </select>
        <small>(Maximum 3 Services can be selected)</small>
        <div className="selected-items">
          <ul>
            {formData.services.map((service) => (
              <li key={service} className="selected-item">
                {service}
                <button type="button" onClick={() => handleRemoveService(service)}>&times;</button>
              </li>
            ))}
          </ul>
        </div>

        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Name"
          required
        />
        <input
          type="text"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          placeholder="Company Name"
          required
        />
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          placeholder="Address"
          required
        />
        <input
          type="text"
          name="mobile"
          value={formData.mobile}
          onChange={handleChange}
          placeholder="Mobile"
          required
        />
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Description"
          required
        />

        <button className='register-button' type="submit" disabled={!otpVerified || isLoading}>
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      {message && <div>{message}</div>}
      {isSubmitted && <p>Submitted Successfully! We will contact you soon.</p>}
    </div>
  );
};

export default ServiceProviderRegistrationForm;
