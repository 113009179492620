import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '../assets/icons/Editicon.png';
import './ServiceProviderProfile.css';
import settingsIcon from '../assets/icons/settings-icon.png';

function ServiceProviderProfile() {
  const [provider, setProvider] = useState(null);
  const [images, setImages] = useState({ profilePic: '', poster: '' });
  const [showSettings, setShowSettings] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editableFields, setEditableFields] = useState({
    mobile: '',
    description: '',
  });
  const [editing, setEditing] = useState({ description: false, mobile: false });
  const [loading, setLoading] = useState({ logo: false, post: false });

  const navigate = useNavigate();

            useEffect(() => {
              const storedProvider = JSON.parse(localStorage.getItem('serviceProvider'));
              const storedImages = JSON.parse(localStorage.getItem('serviceProviderImages'));

              if (!storedProvider) {
                  navigate('/serviceprovider/login');
                  return;
              }             

              // Set the provider state
              setProvider(storedProvider);

              // Set the images (profilePic and poster)
              setImages(storedImages);

              setEditableFields({
                  mobile: storedProvider.mobile,
                  description: storedProvider.description || ''
              });
          }, [navigate]);

      
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableFields({ ...editableFields, [name]: value });
  };

  const handleLogout = () => {
    localStorage.removeItem('serviceProvider');
    navigate('/serviceprovider/login');
  };

  const handleSaveChanges = () => {
    fetch(`https://api.straightlineuae.com/api/serviceproviders/${provider._id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(editableFields),
    })
      .then(response => response.json())
      .then(data => {
        setProvider({ ...provider, ...editableFields });
        localStorage.setItem('serviceProvider', JSON.stringify({ ...provider, ...editableFields }));
        alert('Profile updated successfully');
        setEditing({ description: false, mobile: false });
      })
      .catch(error => console.error('Error updating profile:', error));
  };

  const handleLogoUpload = (e) => {
    const formData = new FormData();
    formData.append('profilePic', e.target.files[0]);
  
    if (provider && provider.email) {
      formData.append('email', provider.email);
    } else {
      alert('Email is missing. Please log in again.');
      return;
    }
  
    setLoading({ ...loading, logo: true });
  
    fetch('https://api.straightlineuae.com/api/service-provider-images/upload-profile-pic', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
            alert(data.error);
        } else {
            const updatedProvider = { ...provider, profilePic: data.profilePic };
            setProvider(updatedProvider);
            setImages(prevImages => ({ ...prevImages, profilePic: data.profilePic }));
            localStorage.setItem('serviceProvider', JSON.stringify(updatedProvider));
            localStorage.setItem('serviceProviderImages', JSON.stringify({ ...images, profilePic: data.profilePic }));
            alert('Profile picture uploaded successfully');
        }
    })
    
      .catch(error => alert(`An error occurred: ${error.message}`))
      .finally(() => setLoading({ ...loading, logo: false }));
  };

  const handlePostUpload = (e) => {
    const formData = new FormData();
    formData.append('poster', e.target.files[0]);
  
    if (provider && provider.email) {
      formData.append('email', provider.email);
    } else {
      alert('Email is missing. Please log in again.');
      return;
    }
  
    setLoading({ ...loading, post: true });
  
    fetch('https://api.straightlineuae.com/api/service-provider-images/upload-post', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
            alert(data.error);
        } else {
            const updatedProvider = { ...provider, poster: data.poster };
            setProvider(updatedProvider);
            setImages(prevImages => ({ ...prevImages, poster: data.poster }));
            localStorage.setItem('serviceProvider', JSON.stringify(updatedProvider));
            localStorage.setItem('serviceProviderImages', JSON.stringify({ ...images, poster: data.poster }));
            alert('Post uploaded successfully');
        }
    })
    
      .catch(error => alert(`Error uploading post: ${error.message}`))
      .finally(() => setLoading({ ...loading, post: false }));
  };


     // Toggle modal open/close
     const handlePosterClick = () => {        
      setIsModalOpen(true);  // This should open the modal
  };
  

  const closeModal = () => {
      setIsModalOpen(false);
  };

  const handleFindAdvertisements = () => {
    const areaQuery = provider.areas.join(',');  // Convert array of areas into a comma-separated string
    const serviceQuery = provider.services.join(',');
    navigate(`/advertisements?area=${areaQuery}&service=${serviceQuery}`);
};

return (
    <div className="profile-container">
      {provider && (
        <>
                  <div className="taskBar">
                    <div className='verification-section'>
                        {provider.verificationStatus ? (
                          <button className="verified">
                           <strong> Verified </strong>
                          </button>
                        ) : (
                          <button className="not-verified" onClick={() => navigate('/Get-Verification')}> <strong> Get verified </strong> </button>
                        )}

                    </div>
                    <div className="settings">
                      <button className='user-name' onClick={() => setShowSettings(!showSettings)}>Welcome <strong>{provider.name}</strong>
                        <img src={settingsIcon} alt="Settings" style={{ width: '30px', marginLeft: '10px' }} />
                      </button>
                      {showSettings && (
                        <div className="settings-menu">
                          <button onClick={handleLogout}>Logout</button>
                          <button onClick={() => navigate('/serviceprovider/change-password')}>Change Password</button>
                        </div>
                      )}
                    </div>
                  </div>

          <div className="header">

              <div className="profile-pic">
              {images.profilePic ? (
                  <img src={`${images.profilePic}?${new Date().getTime()}`} alt="Logo" />
              ) : (
                  <div className="default-logo">{provider.companyName.charAt(0)}</div>
              )}                
                <input id="file-upload-logo" type="file" accept=".jpg,.jpeg,.png" onChange={handleLogoUpload} disabled={loading.logo} />
                <label htmlFor="file-upload-logo" className="edit-btn1">
                  {loading.logo ? 'Uploading...' : images.profilePic ? 'Change Logo' : 'Add Logo'}
                </label>
              </div>

              <div className="company-info">
                <h1>{provider.companyName}</h1>
                <div className="description-container">
                  {editing.description ? (
                    <>
                      <textarea
                        name="description"
                        value={editableFields.description}
                        onChange={handleInputChange}
                        className="editable-textarea"
                        rows="3"
                      />
                      <button className="edit-btn" onClick={handleSaveChanges}>Save</button>
                    </>
                  ) : (
                    <>
                      <p className='aboutus'
                        dangerouslySetInnerHTML={{
                          __html: editableFields.description.replace(/\n/g, '<br />')
                        }}
                      />
                      <img src={EditIcon} alt="Edit" className="edit-icon" onClick={() => setEditing({ ...editing, description: true })} />
                    </>
                  )}
                </div>
              </div>
            </div>

        <div className="line"></div>

          <div className="services-areas">
            <div className="section-block services">
              <h3>Services</h3>
              <p>{provider.services.join(', ')}</p>
            </div>
            <div className="section-block areas">
              <h3>Areas</h3>
              <p>{provider.areas.join(', ')}</p>
            </div>
          </div>

          <div className="contact-details">
            <h3>Contact Details</h3>
            <p>
              <strong>Phone:</strong> {editableFields.mobile}
              <img src={EditIcon} alt="Edit" className="edit-icon" onClick={() => setEditing({ ...editing, mobile: true })} />
            </p>
            <p><strong>Email:</strong> {provider.email}</p>
            <p><strong>Contact Person:</strong> {provider.name}</p>
            <p><strong>Address:</strong> {provider.address}</p>
          </div>
                
            <div className="poster-section">
              <div className="post-images" onClick={handlePosterClick}>
                  {images.poster ? (
                    <img src={`${images.poster}?${new Date().getTime()}`} alt="Logo" />
                  ) : (
                    <div className="default-poster">Upload Poster</div>
                  )}
                <input id="file-upload-poster" type="file" accept=".jpg,.jpeg,.png" onChange={handlePostUpload} disabled={provider.post} />
                <label htmlFor="file-upload-poster" className="post-btn1">
                  {loading.post ? 'Uploading...' : images.poster ? 'Change Logo' : 'Add Poster'}
                </label>
              </div>
            </div>
          

            <div className="advertisements-section">
              {provider.verificationStatus ? (
                <button className="find-ads-btn" onClick={handleFindAdvertisements}>
                Click Here to Find Customer Advertisements in Your Area
              </button>
              
              ) : (
                <button className="verification-message">Please get verified to access advertisements in your area.</button>
              )}
            </div>

                {/* Modal Section */}
                {isModalOpen && (
                    <div className="poster-close" onClick={closeModal}>                            
                      <span className="poster-close-modal" onClick={closeModal}>&times;</span>
                      <img className="poster-full" src={`${images.poster}?${new Date().getTime()}`} alt="Poster Full View" />
                    </div>
                )}

        </>
      )}
    </div>
  );
}

export default ServiceProviderProfile;
