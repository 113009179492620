import React from 'react';
import './AdvertiseServicePage.css';
import { useNavigate } from 'react-router-dom';
import AdvServiceHeader from '../assets/images/Advertise_service_header.jpg';

const AdvertiseServicePage = () => {
  const navigate = useNavigate();

  const handleOpenForm = () => {
      navigate('/advertise-service-form'); // Adjust the route as per your setup

};

  return (
    <div className="advertise-service-page">
      <div className='adv-service-img'>
      <img src={AdvServiceHeader} alt="Home" />

      <h2>
        You can post your requirements for Free
      </h2>
      <p>
        - Companies will reach out to you with the best quotes.<br />
        - No more browsing ads<br />
        - Just sit back and let the best providers compete to offer you their services.
      </p>

      <h3>How It Works</h3>      
        <ol>
          <li>Fill out the advertisement form with your service needs and contact details.</li>
          <li>Your advertisement will be visible to thousands of service providers in your area.</li>
          <li>Wait for service providers to contact you with their offers and choose the one that suits you best!</li>
        </ol>

        <h3>Why Use This Service?</h3>
        <p style={{ color: '#555'}}>
          Our platform simplifies the process of finding service providers. Instead of reaching out to multiple companies, you can post your requirements and let them come to you. This saves you time, effort, and ensures you get the best service without the hassle.
        </p>

      </div>        
      <button className='adservicebutton' onClick={handleOpenForm}>Open Advertisement Form</button>
            
    </div>
  );
};

export default AdvertiseServicePage;
