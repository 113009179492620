import React, { useState } from 'react';
import './TrackAdvertisement.css';

function TrackAdvertisement() {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [advertisementDetails, setAdvertisementDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState(''); 

  const handleTrackingSubmit = (e) => {
    e.preventDefault();
    fetch(`https://api.straightlineuae.com/api/advertise-service/track?trackingNumber=${trackingNumber}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data._id) { // Check if data has an _id, which means it's a valid response
          setAdvertisementDetails(data);
          setErrorMessage(''); // Clear any existing error messages
        } else {
          setAdvertisementDetails(null);
          setErrorMessage('Advertisement not found. Please check the tracking number.');
        }
      })
      .catch((error) => {
        console.error('Error fetching advertisement details:', error);
        setErrorMessage('An error occurred while fetching advertisement details.'); // Handle fetch errors
      });
  };
  

const handleDelete = () => {
  if (advertisementDetails && advertisementDetails._id) {
      fetch(`https://api.straightlineuae.com/api/advertise-service/${advertisementDetails._id}`, {
          method: 'DELETE',
      })
      .then(response => response.json())
      .then(data => {
          alert(data.message);
          setAdvertisementDetails(null);
      })
      .catch(error => console.error('Error deleting advertisement:', error));
  }
};


  const calculateElapsedTime = (advertisementDate) => {
    const now = new Date();
    const adDate = new Date(advertisementDate);
    const diffTime = Math.abs(now - adDate);
    
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    if (diffHours < 24) {
        return `${diffHours} hours ago`;
    } else {
        const diffDays = Math.floor(diffHours / 24);
        return `${diffDays} days ago`;
    }
};
const timeElapsed = advertisementDetails ? calculateElapsedTime(advertisementDetails.date) : '';

return (
  <div className="track-advertisement-container">
    <h2>Track Your Advertisement</h2>
    <form onSubmit={handleTrackingSubmit}>
      <label>
        Tracking Number:
        <input 
          type="text" 
          value={trackingNumber} 
          onChange={(e) => setTrackingNumber(e.target.value)} 
          required 
        />
      </label>
      <button type="submit">Track</button>
    </form>
    {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error message here */}
    {advertisementDetails && (
      <div className="advertisement-details">
        <p><strong>Name:</strong> {advertisementDetails.name}</p>
        <p><strong>Email:</strong> {advertisementDetails.email}</p>
        <p><strong>Mobile:</strong> {advertisementDetails.mobile}</p>
        <p><strong>Emirates:</strong> {advertisementDetails.emirate}</p>
        <p><strong>Area:</strong> {advertisementDetails.area}</p>
        <p><strong>Description:</strong> {advertisementDetails.description}</p>
        <p><strong>Service:</strong> {advertisementDetails.service}</p>
        <p><strong>Time Elapsed:</strong> {timeElapsed}</p>
        <button onClick={handleDelete}>Delete Advertisement</button>
      </div>
    )}
  </div>
);

}

export default TrackAdvertisement;
